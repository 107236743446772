<template>
  <div class="Awarp">
    <dashCard
      class="energykanbanBox4"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('operationAm6_count','事件状态统计', getZEdata)}}</template>
      <template slot="aside"> </template>
      <div class="card-content1">
        <div class="type-group">
          <span
            class="pick-type"
            v-for="(item, idx) in timetType"
            :key="idx"
            @click="changeType(item.code)"
          >
            <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
              item.name
            }}</font>
          </span>
        </div>
        <a-date-picker
          v-if="searchObj.TYPE == '按天'"
          :placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-week-picker
          v-if="searchObj.TYPE == '按周'"
          :placeholder="commonLangHandler('powerBox28_selectWeek','选周', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-month-picker
          v-if="searchObj.TYPE == '按月'"
          :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-date-picker
          v-if="searchObj.TYPE == '按年'"
          :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
          mode="year"
          :open="panelOpen"
          @openChange="openChange"
          @panelChange="panelChange"
          format="YYYY"
        />
      </div>
      <div class="card-content2">
        <chartBoard ref="chart1" :option="chartOption1" />
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <energykanbanBox4
          :option="option"
          :isModal="true"
          :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
    <script>
export default {
  name: "energykanbanBox4",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中
      sum: 0,
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
      
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      panelOpen: false,
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      colorList: ["#2A35FF", "#4C80EE"],
    };
  },
  computed: {
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "今日",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "本周",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "本月",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "全年",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange(value) {
      console.log("onChange", value, this.searchObj);
      this.getData();
    },
    //每月报警预警数
    getData() {
      let data = {
        deptCode: this.currentSelectDeptInfo.CODE,
      };
      Object.assign(data, this.transformSearch());
      data.dateType = data.type;
      delete data.type;
      console.log(data);
      this.$axios({
        url: Config.dev_url + "/api-apps/eventInfo/statsByStatus",
        method: "post",
        data,
      }).then((res) => {
        this.isLoading = true;
        let result = res.data.data.valueList;
        var chart1 = {seriesData: []};
        chart1.seriesData = result
        this.detailInfo.chart1 = chart1;
        this.sum = 0
        result.forEach(item=>{
          this.sum += Number(item.cnt)
        })
        this.initEchart();
        this.isLoading = false;
      });
    },
    initEchart() {
      var { seriesData } = this.detailInfo.chart1;
      let seriesData1 = [];
      seriesData.forEach((item, idx) => {
        var obj = {
          name: item.alertstatus,
          value: item.cnt,
        };

        seriesData1.push(obj);
      });
      var option = {
        color: this.colorList,
        animation: false,
        layoutAnimation: false,
        title: [
          {
            text: "",
            subtext: this.sum,
            textStyle: {
              fontSize: 12,
              color: "#86909c",
              fontFamily: "ABBvoice_WCNSG_Rg",
            },
            subtextStyle: {
              fontSize: 24,
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#1D2129",
              fontWeight: "600",
            },
            textAlign: "center",

            x: "34%",
            y: "35%",
          },
          {
            text: this.LangHandler('totalitems','总计(条)'),
            textStyle: {
              fontSize: 12,
              color: "#86909c",
              fontFamily: "ABBvoice_WCNSG_Rg",
            },
            textAlign: "center",

            x: "34%",
            y: "50%",
          },
        ],
        tooltip: {
          trigger: "item",
        },
        legend: {
          // right: "0%",
          left:"70%",
          top: "30%",
          orient: "vertical",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#1d2129",
          },
        },
        series: [
          {
            itemStyle: {
              normal: {
                borderColor: "#172659",
                borderWidth: 0,
              },
            },
            type: "pie",
            radius: ["70%", "80%"],
            center: ["35%", "50%"],
            labelLine: {
              normal: {
                lineStyle: {
                  color: "#34569D",
                },
              },
            },
            label: {
              normal: {
                show: false,
                formatter: (params) => {
                  return (
                    "{name|" +
                    params.name +
                    "}{percent|" +
                    params.percent.toFixed(0) +
                    "%}"
                  );
                },

                padding: [0, -5, 0, -5],
              },
            },
            data: seriesData1,
          },
        ],
      };
      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },

  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.energykanbanBox4 {
  .card-content1 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .type-group {
      width: 160px;
    }
  }
  .card-content2 {
    flex: 1;
    height: 100%;
    div {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
